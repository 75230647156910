<template>
  <div>
    <x-form-group title="Seleciona una fuente de financiamiento" required>
      <x-select
        v-model="inputFuenteFinanciamientoId"
        :dispatchPath="null"
        getterListPath="fuenteFinanciamientoModule/getList"
        :formatListWith="['id', 'nombre']"
        no-validate
        no-clear-button
        prepend-text="Fuente de financiamiento"
        default-text="-- Selecciona una fuente de financiamiento --"
        @input="getDesglosePorPartida"
      />
    </x-form-group>

    <b-form-group>
      <x-alert-with-errors
        :error="desglose.error"
        title="Separación de importes"
        :message="desglose.message"
        :errors="desglose.errors"
      />
    </b-form-group>

    <b-card no-body class="card card-header-actions mb-3">
      <b-card-header>
        <div>
          Proyecciones presupuestales
        </div>
        <b-button
          class="btn-sm btn-outline-primary mr-1 mb-1 mb-sm-0"
          size="sm"
          variant="white"
          @click="addProyeccion"
        >
          Agregar Proyección
        </b-button>
      </b-card-header>
      <b-card-body class="p-1 px-0">
        <b-table
          :fields="desglose.fields"
          :items="desglose.response"
          responsive
          bordered
          hover
          striped
          sticky-header
          head-variant="light"
          show-empty
          outlined
          small
          style="min-height: 450px"
          :busy="desglose.isLoading"
          :filter="filter"
          :empty-text="emptyText"
        >
          <template #table-busy>
            <div class="text-center my-2" style="min-height: 25vh">
              <b-spinner class="align-middle mr-3"></b-spinner>
              <strong>Cargando...</strong>
            </div>
          </template>

          <!-- Proyecto -->
          <template #cell(proyectonombre)="row">
            <div class="text-sm font-weight-bolder">
              {{ row.item.proyecto.nombre }}
            </div>
          </template>

          <!-- Unidad administrativa -->
          <template #cell(unidadadministrativanombre)="row">
            <div class="text-sm font-weight-bolder">
              {{ row.item.unidad_administrativa.nombre }}
            </div>
          </template>

          <!-- Importe -->
          <template #cell(importe)="row">
            <x-input-group-currency
              :item="row.item"
              :value="row.item.importe"
              size="sm"
              attach-value-name="importe"
              :payload="{
                periodo_fiscal_id: row.item.periodo_fiscal_id,
                fuente_financiamiento_id: row.item.fuente_financiamiento_id,
                proyecto_id: row.item.proyecto.id,
                unidad_administrativa_id: row.item.unidad_administrativa.id,
                prestacion_id: row.item.prestacion_id,
                mes: row.item.mes
              }"
              dispatch-path="planeacionModule/updateAnalisisPresupuestoDesgloseItem"
              @on-show-response="onUpdated($event)"
              @on-dispatched="updateLocalList(row.item.id, $event.data, $event)"
            />
          </template>
        </b-table>
      </b-card-body>
    </b-card>

    <!-- Sidebar para crear nuevos registros de proyección presupuestal -->
    <b-sidebar
      id="addProyeccionPresupuestal"
      right
      shadow
      backdrop
      v-model="showSidebar"
      title="Agregar Proyección"
    >
      <template #default="{ hide }">
        <!-- Form para importar layout de nómina -->
        <div class="p-3">
          <analisis-desglose-partida-form
            :prestacion_id="prestacion_id"
            :periodo_fiscal_id="periodo_fiscal_id"
            @on-result="hide()"
          >
            <template #btn-cancel-place="{clearForm}">
              <b-button
                variant="dark"
                size="sm"
                @click="
                  clearForm()
                  hide()
                "
              >
                Cerrar
              </b-button>
            </template>
          </analisis-desglose-partida-form>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import XInputGroupCurrency from '@/components/Shared/XInputGroupCurrency'
import AnalisisDesglosePartidaForm from './AnalisisDesglosePartidaForm'
import _ from 'lodash'
import planeacionMixin from '@/mixins/planeacionMixin'

export default {
  name: 'AnalisisDesglosePartidaTable',

  mixins: [planeacionMixin],

  components: {
    XInputGroupCurrency,
    AnalisisDesglosePartidaForm
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    filter: {
      type: String,
      required: false,
      default: null
    }
  },

  created () {
    //
    // Prepara los datos para asignarlos localmente
    // y trabajar con ellos haciendo cálculos.
    this.init(this.item)
  },

  data () {
    return {
      //
      // Cuando inicia el modal, el usuario debe de elegir una fuente de financiamiento
      // para poder hacer las ediciones adecuadas.
      inputFuenteFinanciamientoId: null,

      //
      // Representa el nuevo valor que se asignaría
      recursoEstatal: 0,
      //
      // Representa el nuevo valor
      ingresosPropios: 0,
      global: 0,
      periodo_fiscal_id: null,
      prestacion_id: null,
      mes: null,
      strMes: '',
      importe: 0,

      //
      // Representa actual valor del recurso estatal.
      currentRecursoEstatal: 0,
      //
      // Representa el actual valor para los ingresos propios.
      currentIntresosPropios: 0,

      desglose: {
        fields: [
          { key: 'unidadadministrativanombre', label: 'Unidad administrativa' },
          { key: 'proyectonombre', label: 'Proyecto' },
          { key: 'importe', label: 'Importe' }
        ],
        isLoading: false,
        response: [],

        error: false,
        message: '',
        errors: {}
      },

      isLoadingFuentes: false,

      emptyText: '',

      // Indica si se debe mostrar el sidebar para agregar proyecciones
      showSidebar: false
    }
  },

  methods: {
    async init (item) {
      this.emptyText = 'Seleccione una fuente de financiamiento'
      this.isLoadingFuentes = true

      //
      // Asigna las variables localmente
      this.recursoEstatal = _.toNumber(item.recursoEstatal)
      this.ingresosPropios = _.toNumber(item.ingresoPropio)
      this.global = _.toNumber(item.global)
      this.periodo_fiscal_id = _.toNumber(item.periodo_fiscal_id)
      this.prestacion_id = _.toNumber(item.prestacion_id)
      this.mes = _.toNumber(item.mes)
      this.strMes = item.strMes

      this.currentRecursoEstatal = this.recursoEstatal
      this.currentIntresosPropios = this.ingresosPropios

      // await this.$getPeriodoFiscalAndFuenteFinanciamiento()
      //
      // Obtiene la lista de fuentes de financiamiento basado en el período fiscal seleccionado
      const getval = await this.$store.dispatch('fuenteFinanciamientoModule/getDefault', {
        fuente: 'fuente_financiamiento_por_defecto',
        periodo_fiscal_id: this.periodo_fiscal_id
      })
      this.$store.commit('fuenteFinanciamientoModule/setFuenteFinanciamiento', getval.data)

      //
      // Obtiene la lista de proyectos basados en el periodo fiscal seleccionado
      if (!this.$store.getters['proyectoModule/hasProyectos']) {
        await this.$store.dispatch('proyectoModule/getProyectos', this.periodo_fiscal_id)
      }

      //
      // Obtiene la lista de las unidades administrativas basado en el periodo fiscal seleccionado
      if (!this.$store.getters['administrativeUnitModule/hasAdministrativeUnits']) {
        await this.$store.dispatch('administrativeUnitModule/getAdministrativeUnits', this.periodo_fiscal_id)
      }

      this.isLoadingFuentes = false
    },

    async getDesglosePorPartida () {
      this.desglose.isLoading = true

      const { error, data, message } = await this.$store.dispatch(
        'planeacionModule/getAnalisisPresupuestoDesglose',
        {
          periodo_fiscal_id: this.periodo_fiscal_id,
          fuente_financiamiento_id: this.inputFuenteFinanciamientoId,
          prestacion_id: this.prestacion_id,
          mes: this.mes
        }
      )
      if (this.desglose.response.length === 0) {
        this.emptyText = 'No se encontraron registros de proyecciones presupuestales para la fuente de financiamiento seleccionada'
      }
      this.desglose.isLoading = false

      this.desglose.error = error
      this.desglose.response = error ? [] : data
      this.desglose.errors = error ? data : {}
      this.desglose.message = message
    },

    updateLocalList (id, newItem, res) {
      if (res.error) return

      const arr = this.desglose.response.map(it => {
        return it.id === id ? { ...it, ...newItem } : it
      })

      this.desglose.response = arr
    },

    onUpdated (res) {
      this.$alert(res, 'Separación de importes', 'notificationAlertXModalMessage')
    },

    addProyeccion () {
      this.showSidebar = true
    }
  }
}
</script>
